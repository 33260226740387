import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Loader from '@/shared/UI/elements/Loader';
import MainLayout from '@/layouts/MainLayout';


const Profile = lazy(() => import('@/pages/Profile'));
const Calendar = lazy(() => import('@/pages/Calendar'));
const Owners = lazy(() => import('@/pages/Owners'));
const Patients = lazy(() => import('@/pages/Patients'));
const Procedures = lazy(() => import('@/pages/Procedures'));
const Statistic = lazy(() => import('@/pages/Statistic'));

const AddPatient = lazy(() => import('@/pages/Patients/AddPatient'));
const AddOwner = lazy(() => import('@/pages/Owners/AddOwner'));

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Loader show={true} />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const DrawerMenuTop = [
  {
    name: 'Calendar',
    path: '/calendar',
    element: <Calendar />,
    icon: 'CalendarMonthIcon',
  },
  {
    name: 'Owners',
    path: '/owners',
    element: <Owners />,
    icon: 'PeopleAltIcon',
  },
  {
    name: 'Patients',
    path: '/patients',
    element: <Patients />,
    icon: 'PetsIcon',
  },
  {
    name: 'Procedures',
    path: '/procedures',
    element: <Procedures />,
    icon: 'DescriptionIcon',
  },
  {
    name: 'Statistic',
    path: '/statistic',
    element: <Statistic />,
    icon: 'BarChartIcon',
  },
  {
    name: 'Settings',
    path: '/settings',
    element: <Profile />,
    icon: 'SettingsIcon',
  },
];

const defaultRoutes = [
  { path: '/owners/add-owner', element: <AddOwner /> },
  { path: '/patients/add-patient', element: <AddPatient /> },
  { path: '/', element: <Navigate to="/calendar" /> },
  { path: '*', element: <Navigate to="/calendar" /> },
];

const routes = [...defaultRoutes, ...DrawerMenuTop];

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: routes,
  },
];

