import { COLORS } from '@/app/providers/theme';
import { fetchUser } from '@/shared/hooks/useAuth';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const HeaderAccount = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: user, refetch: refetchUser } = useQuery('user', fetchUser, { enabled: false, refetchInterval: false });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    removeCookie('user');
    refetchUser();
  };

  return (
    <>
      <Box
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          ml: 2,
          cursor: 'pointer'
        }}>
        <Box>
          <Typography variant="subtitle1" sx={{ color: COLORS.black }}>{user?.firstname} {user?.lastname}</Typography>
          <Typography variant="body1">{user?.telephone}</Typography>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            minWidth: 196,
            borderRadius: '8px',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '& .MuiList-root': {
              p: '8px',
            },
            'li': {
              minHeight: '40px',
              p: '8px'
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate('/settings')}>
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderAccount;
