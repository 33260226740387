import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useCookies } from 'react-cookie';

export const AppRoutes = () => {
  const [cookies] = useCookies(['user']);

  const routes = cookies?.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};