import API from '@/app/libs/axios';
import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { Login, Register } from '../models/auth.model';
import { urls } from '../consts/urls';

export const fetchUser = async (): Promise<any> => {
  try {
    return await API.get(urls.user);
  } catch (err: any) {
    console.log(`Fetch user error: ${err}`);
    throw Error(err);
  }
};

export const signUp = async (data: Register) => {
  try {
    return await API.post(urls.register, data);
  } catch (err: any) {
    console.log('err 2', err);
    throw Error(err);
  }
};

export const login = async (data: Login) => {
  try {
    return await API.post(urls.login, data);
  } catch (err: any) {
    console.log('err 2', err);
    throw Error(err);
  }
};

export const logout = async () => {
  try {
    await API.post(urls.logout);
    console.log('resp logout');
  } catch (err: any) {
    console.log('err', err);
    throw Error(err);
  }
};

export const updateUser = async (data: any) => {
  try {
    const { user }: any = await API.post(urls.userUpdate, data);
    return user;
  } catch (err: any) {
    console.log(`Update User error: ${err}`);
    throw Error(err);
  }
};

export const useLogoutMutation = () => {
  return useMutation(logout);
};
