import theme from '@/app/providers/theme';
import { Box, CircularProgress } from '@mui/material';

const Loader = (
  { show, width = '100%', height = '100%', position = 'absolute', ...props }:
  { show: boolean, width?: string, height?: string, position?: string, [key: string]: any }
) => {

  if (!show) return null;

  return (
    <Box sx={{
      position,
      zIndex: 1101,
      width,
      height,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.background.paper,
      opacity: .6,
      ...props
    }}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
