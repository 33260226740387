import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { ServiceDialogProvider } from './dialog';

import theme from '../providers/theme';
import Loader from '@/shared/UI/elements/Loader';

import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from '@/shared/UI/styled/global';
import { QueryClient, QueryClientProvider } from 'react-query';
import UserService from '../service/UserProvider';

type AppProviderProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Loader show={true} />
        </div>
      }
    >
      <Router>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ServiceDialogProvider>
              <UserService />
              {children}
            </ServiceDialogProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </Router>
      <StyledToastContainer position="top-center" newestOnTop />
    </Suspense>
  );
};
