import { CenterContainer } from '@/shared/UI/styled/global';
import { Grid } from '@mui/material';

const AuthLayout = ({ children }: any) => {
  return (
    <Grid
      container
      component="main"
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh">
      <CenterContainer>
        {children}
      </CenterContainer>
    </Grid>
  );
};

export default AuthLayout;
