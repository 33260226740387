export interface AppLogo {
  width?: number;
  height?: number;
}

const AppLogo = ({ width = 100, height = 100, ...props }: any) => {
  return (
    <img
      src="/images/gui/logo_header.svg"
      alt="GavKit"
      width={width}
      height={height}
      style={{ maxWidth: '100%', verticalAlign: 'middle' }}
      {...props}
    />
  );
};

export default AppLogo;
