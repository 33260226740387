import memoize from 'fast-memoize';

// This helper will loop through an object and transform the keys to snake or camel
// depending on the function called. It will utilize memoization/cache to only compute
// the values when the string has never been computed in the first place.

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const loopKeysRecursively = ({ data, toFn, originalFn }: any) => {
  if (isObject(data)) {
    if (data instanceof Date) {
      return data.toISOString();
    }
    const n: any = {};
    Object.keys(data).forEach(k => (n[toFn(k)] = originalFn(data[k])));
    return n;
  } else if (isArray(data)) {
    return data.map((i: any) => originalFn(i));
  }
  return data;
};

const toCamel = (s: string) => s.replace(/(_\w)/g, (m: any) => m[1].toUpperCase());
const cachedToCamel = memoize(toCamel);

const keysToCamel = (data: any) => {
  return loopKeysRecursively({
    data,
    toFn: cachedToCamel,
    originalFn: keysToCamel
  });
};

const toSnake = (s: string) => s.replace(/[\w]([A-Z])/g, m => m[0] + '_' + m[1]).toLowerCase();
const cachedToSnake = memoize(toSnake);

const keysToSnake = (data: any) => {
  return loopKeysRecursively({
    data,
    toFn: cachedToSnake,
    originalFn: keysToSnake
  });
};

const parseToCamel = (model: any, compare: any) => {
  const compareToCamel = keysToCamel(compare);
  return Object.keys(model).reduce((acc: any, key: any) => {
    const lowerCaseKey = key.toLowerCase();
    acc[key] = compareToCamel[lowerCaseKey] ?? compareToCamel[key];
    return acc;
  }, {});
};

export { keysToCamel, keysToSnake, parseToCamel, toSnake, toCamel };
