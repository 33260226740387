import { Suspense, lazy, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Loader from '@/shared/UI/elements/Loader';
import AuthLayout from '@/layouts/AuthLayout';

const Login = lazy(() => import('@/pages/Auth/Login'));
const Register = lazy(() => import('@/pages/Auth/Register'));
const ForgotPassword = lazy(() => import('@/pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('@/pages/Auth/ResetPassword'));

const App = () => {
  return (
    <AuthLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Loader show={true} />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </AuthLayout>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '/',  element: <Navigate to="login" /> },
      { path: '*', element: <Navigate to="login" /> },
    ],
  },
];