import { AppProvider } from './providers';
import { AppRoutes } from '../shared/routes';

export const App = () => {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};

export default App;