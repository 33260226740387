import { fetchUser, logout, useLogoutMutation } from '@/shared/hooks/useAuth';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';

const UserService = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const logoutMutation = useLogoutMutation();
  const { refetch: refetchUser } = useQuery('user', fetchUser, {
    enabled: false,
    refetchInterval: false,
    onError: () => {
      logoutMutation.mutate();
      removeCookie('user');
    }
  });

  useEffect(() => {
    if (cookies?.user) {
      refetchUser();
    }
  }, []);

  return null;
};

export default UserService;
