interface KeyVal {
  [key: string]: string;
}

export const urls: KeyVal = {
  user: 'auth/me',
  userUpdate: 'auth/edit',
  register: 'auth/register',
  login: 'auth/login',
  logout: 'auth/logout',
};
