import { createTheme } from '@mui/material/styles';

export const COLORS = {
  primary: '#FFBE0D',
  secondary: '#5c5c5c',
  white: '#FFFFFF',
  grey: '#F6F6F6',
  black: '#000000',
  red: '#d9533f',
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
      // dark: COLORS.primaryDark,
    },
    secondary: {
      main: COLORS.secondary,
      // dark: COLORS.secondaryDark,
    },
    background: {
      // default: COLORS.grey,
      paper: COLORS.white,
    },
    // info: {
    //   main: '',
    // },
    // error: {
    //   main: '',
    //   dark: '',
    //   light: ''
    // },
    // success: {
    //   main: '',
    // },
    // common: {
    //   black: '',
    //   white: '',
    // },
  },
  typography: {
    fontFamily: 'OpenSans',
    h1: {
      color: COLORS.primary,
      fontWeight: 600,
      fontSize: 40,
      lineHeight: '48px',
    },
    h2: {
      color: COLORS.primary,
      fontSize: 32,
      fontWeight: 500,
      lineHeight: '24px',
    },
    h3: {
      color: COLORS.primary,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'left',
    },
    h4: {
      color: COLORS.primary,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    h5: {
      color: COLORS.primary,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
    },
    h6: {
      color: COLORS.primary,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '20px',
      textAlign: 'left',
    },
    subtitle1: {
      color: COLORS.primary,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    subtitle2: {
      color: COLORS.secondary,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    body1: {
      color: COLORS.secondary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      color: COLORS.secondary,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  components: {
    MuiContainer: {},
    MuiAutocomplete: {
      styleOverrides: {}
    },
    MuiSelect: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTextField: {
      styleOverrides: {},
      defaultProps: {
        focused: false,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
      }
    },
    MuiSwitch: {
      styleOverrides: {}
    },
    MuiFormControlLabel: {
      styleOverrides: {}
    },
    MuiCheckbox: {
      styleOverrides: {},
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '20px',
          position: 'relative',
          transform: 'initial',
          marginBottom: '5px',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: COLORS.black,
            padding: '10px 12px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
            borderRadius: 4,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'rgba(163, 173, 181, 0.20)',
            transition: 'border .3s'
          },
          '&:hover': {
            '& > fieldset': {
              borderColor: `${COLORS.primary} !important`
            }
          },
          '& legend': {
            display: 'none'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {},
    },
    MuiStack: {
      styleOverrides: {
        root: {
          paddingTop: '0 !important',
        }
      }
    },
  },
});

export default theme;
