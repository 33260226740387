import Cookies from 'js-cookie';

export const getCookie = (name = 'user', prop?: string) => {
  const cookie = Cookies.get(name);

  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    if (prop) {
      return parsedCookie[prop];
    }
    return parsedCookie;
  }

  return null;
};
